import StepOne from "components/steps/StepOne";
import StepTwo from "components/steps/StepTwo";
import StepThree from "components/steps/StepThree";
import Container from "components/UI/Container/Container";
import React, { useState } from "react";
import s from "./Steps.module.scss";

const Steps = () => {
  const [widget, setWidget] = useState(false);

  return (
    <div className={s.steps}>
      <Container>
        <div className={s.items}>
          <StepOne />
          <StepTwo />
          <StepThree visible={widget} setWidget={setWidget} />
        </div>
      </Container>
    </div>
  );
};

export default Steps;
