import Layout from "layouts";
import Home from "pages/Home";
import Policy from "pages/Policy";
import React from "react";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { Route } from "react-router-dom";
import "styles/App.scss";

function App() {
  const tagManagerArgs = {
    gtmId: "GTM-W7NPTZ8",
  };

  TagManager.initialize(tagManagerArgs);
  ReactGA.initialize("G-3N7PX4HLB3");

  return (
    <Layout ga={ReactGA}>
      <Route path="/" element={<Home ga={ReactGA} />} />
      <Route path="/privacy" element={<Policy />} />
      <Route path="/*" element={<Home ga={ReactGA} />} />
    </Layout>
  );
}

export default App;
