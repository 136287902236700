import axios from "axios";

export const getWord = async (word) => {
   const response = await axios.get(`https://api.pronounce.live/a/search/${word}`);
   return response;
}

export const getWordDict = async (word) => {
   const response = await axios.get(`https://api.pronounce.live/a/dict/${word}`);
   return response;
}
export const getWordPronunciation = async (word) => {
   const response = await axios.get(`https://api.pronounce.live/a/speech/${word}`);
   return response && `https://api.pronounce.live/a/speech/${word}`
}
